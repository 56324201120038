<script
    lang="ts"
    setup
>
    type Props = {
        modelValue: any
        name: string
        label?: string
        error?: string
        inputValueTrue?: any
        inputValueFalse?: any
        disabled?: boolean
        preventValueChange?: boolean
        withSafeAreaIndent?: boolean
    }

    type Emit = {
        (event: 'update:modelValue', value: any): void
        (event: 'update:error', value: string): void
        (event: 'before-change'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        label: undefined,
        error: '',
        inputValueTrue: true,
        inputValueFalse: false,
        disabled: false,
        preventValueChange: false,
        withSafeAreaIndent: false,
    })

    const emit = defineEmits<Emit>()

    const inputModel = computed({
        get() {
            return props.modelValue === props.inputValueTrue
        },
        set(value) {
            if (inputModel.value === value) {
                return
            }

            emit('before-change')

            if (props.preventValueChange) {
                return
            }

            if (props.error) {
                emit('update:error', '')
            }

            emit('update:modelValue', value ? props.inputValueTrue : props.inputValueFalse)
        },
    })

    const style = useCssModule()

    const toggleClass = computed<string[]>(() => {
        const classes = [ style['toggle'] ]

        if (inputModel.value) {
            classes.push(style['toggle--active'])
        }

        if (props.withSafeAreaIndent) {
            classes.push(style['toggle--with-indent'])
        }

        if (props.disabled) {
            classes.push(style['toggle--disabled'])
        }

        return classes
    })
</script>

<template>
    <label :class="toggleClass">
        <span
            key="input-wrapper"
            :class="$style['toggle__wrapper']"
        >
            <input
                ref="inputRef"
                :name="props.name"
                type="checkbox"
                :disabled="props.disabled || props.preventValueChange"
                :class="$style['toggle__wrapper__input']"
                autocomplete="off"
                @input="inputModel = !inputModel"
            />

            <span :class="$style['toggle__wrapper__slider']"></span>
        </span>

        <span
            v-if="props.label"
            key="label"
            :class="$style['toggle__label']"
        >
            <slot name="label-text">
                {{ props.label }}
            </slot>
        </span>

        <AppFormFieldError
            :disabled="props.disabled"
            :error-message="props.error"
        />
    </label>
</template>

<style
    lang="sass"
    module
    scoped
>
    .toggle
        display: flex
        align-items: center
        min-height: 18px
        cursor: pointer

        &--with-indent
            min-height: 24px

        $toggle: &

        &__wrapper
            display: flex
            padding: 2px
            min-width: 32px
            min-height: 18px
            width: 32px
            height: 18px
            background: #e3e5eb
            border-radius: 9px
            transition: background-color var(--transition-default-duration-with-ease)
            will-change: background-color

            #{$toggle}:focus-within &
                border-color: #000

            #{$toggle}--disabled &
                background: #e3e5eb !important
                cursor: not-allowed

            #{$toggle}--disabled#{$toggle}--active &
                background: #fff5c6 !important

            #{$toggle}--active &
                background: var(--color-primary)

                &:hover
                    background: #efcc1a

                &:active
                    background: #efb31a

            &:hover
                background: #ced2dc

            &:active
                background: #b5bccf

            &__input
                appearance: none
                outline: none
                opacity: 0
                width: 0
                height: 0

            &__slider
                pointer-events: none
                position: relative
                min-width: 14px
                min-height: 14px
                width: 14px
                height: 14px
                background: #000
                border-radius: 50%
                transition: transform var(--transition-default-duration-with-ease)
                will-change: transform

                &:before
                    content: ''
                    position: absolute
                    top: 0
                    left: 0
                    height: 14px
                    width: 14px
                    background: rgba(46, 57, 90, 0.15)
                    border-radius: 50%
                    transition: transform var(--transition-default-duration-with-ease)
                    will-change: transform

                #{$toggle}:focus-within &
                    &:before
                        transform: scale(1.6)

                #{$toggle}--active &
                    transform: translateX(14px)

                #{$toggle}--disabled &
                    background: #a7a7a7

        &__label
            margin-left: 14px
            font-size: 14px
            font-weight: 400
            line-height: 130%
            color: #000
</style>
